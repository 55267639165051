.tabs {
  position: relative;
  display: flex;
  transition-duration: 1s;
}

#tabsContainer {
  /* border: 1px solid pink; */
  /* position: relative; */
}

.tab,
.tabActive {
  /* border: 2px solid pink; */
  margin: 6px 3px 0 0;
  padding: 8px;
  height: 35px;
  transition-duration: 0.3s;
  background-color: rgb(108, 107, 107);
  min-width: 120px;
  border-radius: 2px 2px 0 0;
  color: var(--text-color);
  text-align: center;
  font-size: 0.9rem;
  white-space: nowrap;
}
.tabActive {
  background-color: var(--bg-color);
  padding: 10px;
  margin: 8px 3px 0 0;
}
.tab:hover {
  background-color: var(--bg-color);
}

.tabClose {
  transition-duration: 0.4s;
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  color: var(--text-color);
  cursor: pointer;
  background-color: #b80c0c;
  left: 0px;
  top: 50px;
  margin-top: 50px;
  padding: 0px 6px;
  font-size: xx-small;
}
.tabClose:before,
.tabClose:after {
  position: absolute;
  top: 16%;
  left: 41%;
  width: 2px;
  height: 70%;
  border-radius: 5px;
  transform: rotate(45deg);
  background: #fff;
  content: "";
}
.tabClose:after {
  transform: rotate(-45deg);
}

.arrow {
  position: absolute;
  z-index: +100;
  transition-duration: 0.3s;
  top: 5.7em;
  width: 15px;
  height: 15px;
  border-top: 8px solid var(--hover-color);
  border-right: 8px solid var(--hover-color);
  box-shadow: 0 0 0 var(--bg-color);
  transition: all 200ms ease;
}

.arrow.left {
  left: 175px;
  transform: translate3d(0, -50%, 0) rotate(-135deg);
  display: none;
}

.arrow.right {
  right: 205px;
  transform: translate3d(0, -50%, 0) rotate(45deg);
  display: none;
}

.arrow:hover {
  border-color: var(--bg-color);
  box-shadow: 0.5vmin -0.5vmin 0 #808080;
  cursor: pointer;
}
