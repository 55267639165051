

/* ### Wrapper for entire site ### */
#maincontainer {
    display:flex;
    flex-direction:column;
    flex-wrap: nowrap;
    width:100%;
    height: 100%;
    max-height: 100%;
    background:rgb(134, 134, 134);
    background-color: var(--nav-bg-color);

}

.contentWrapper {
    margin: 0.0%;
    margin-top: 0.05em;
    height: 100%!important;
}

/* ### Navbar ### */
#navcontainer {
    height:4em;
    display:flex;
    flex-direction: row;
    background: var(--sides-bg-color);
    /* box-shadow: 0 20px 0px 100px rgba(0,0,0,0.2); */
    /* border-bottom: 9px solid var(--nav-bg-color); */
    align-items: center;
}

#navcontainer img {
    height:3.5em;
    margin-left: 0.5em;
}

#navcontainer .USNLogo {
    position:fixed;
    right:2em;

}

#navcontainer .USNLogo img {
    width:13em;
    cursor: pointer;
}

#navcontainer .USNLogo img:hover {
    opacity: 0.5;
}

/* Role-selection */

#navcontainer ul .right-nav {
    position: fixed;
    right: 20em;
    z-index: 100;
}

#navcontainer ul li {
    list-style: none;
    margin: 0 auto;
    display: inline-block;
    padding: 0 30px;
    position: relative;
    text-decoration: none;
    text-align: center;
}

#navcontainer ul li:hover {
    cursor: pointer;
}

#navcontainer ul li ul {
    visibility: hidden;
    opacity: 0;
    display: none;
    position:absolute;
    padding-left: 0;
    left: 0;

    background: white;
}

#navcontainer ul span li:hover > ul,
#navcontainer ul span li ul:hover {
    z-index: 10;
    visibility: visible;
    opacity: 1;
    display: block;
    min-width: 250px;
    text-align: left;
    padding-top: 20px;
    box-shadow: 0px 3px 5px -1px #ccc;
} 

#navcontainer ul li ul li {
    z-index: 1;
    clear:both;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    border-style: none;
    cursor: pointer;
}

#navcontainer ul li ul li:hover {
    padding-left: 10px;
    border-left: 2px solid #3ca0e7;
    transition: all 0.3s ease;
}

#navcontainer ul li ul li { transition: all 0.5s ease;}





/* ### Menu ### */
#menuwrapper {
    width: 18em;
    max-width: 12%;
    border-right: 1px solid var(--nav-bg-color);
    margin-bottom: 1em;
    background: var(--sides-bg-color) !important;
    height: 100%;
}

#menuwrapper .favoriteElementLogo {
    opacity: 100%;
    width: 1.5em;
    height: 1.5em;
}

aside #menucontainer {
    height: 100%;
}

#menuheader {
    margin: 10px 20px;
    text-align: center;
    color: #fff;
}

#menuheader .sponsorLogo {
    margin-top: 10px;
    width: 100%;
    height: auto;
}

#menuheader #searchBar {
    width: 140px;
    margin-left: -15px;
    padding: 5px;
}

#menuheader #searchUl {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
}

#menuheader #searchUl::-webkit-scrollbar-track {
    background-color: transparent;
}

#menuheader #searchUl li a {
    border-bottom: 2px solid var(--bg-color);
    padding: 5px 0 3px 5px;
    text-decoration: none;
    color: black;
    display: block;
}

#menuheader #searchUl li a:hover:not(.header) {
    background-color: var(--hover-color);
    color: var(--text-color);
}

.menuRoot {
    padding:1.5em;
}

.categoryHeader {
    text-align: center !important;
    background-color: var(--bg-color) !important;
    color: white !important;
    border-bottom: 1px solid white !important;
    border-bottom-width: 50%;
}


/* ### Maincontainer for chart rendering ### */
div.chartcontainer {
    margin-top:-0.0%;
    height: 100%;
}



.tabscontainer {
    margin-left:-0.35em;
    width:100%;
}

div.chartcontainer .chart {
    /* border-bottom: 1px var(--nav-bg-color); */
    /* box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.1); */
    z-index: 1 !important;

}

.highcharts-container {
    position: inherit !important;
    /* border-radius: 5px; */
    /* box-shadow: 20px 20px 10px 20px rgba(0,0,0,0.5); */
  }

/* change color of highcharts-react: */
.highcharts-background {
    fill: var(--bg-color)
}

.highcharts-tooltip {
    z-index: 99 !important;
  }

div.chartcontainer #relatedindicators {
    display:flex;
    padding:0.5em;
    padding-bottom: 0em;
    margin-top:0.0%;
    margin-right:-0.0%;
    margin-bottom: 0;
    /* box-shadow: 0 -1px 5px -2px rgba(0,0,0,0.5); */
    height:100%;
    background: var(--bg-color);
    border-top: 1px solid var(--nav-bg-color);
    /* border-right: 1px solid var(--nav-bg-color); */
    border-radius: 0px;
}

/* Hide #relatedindicators when height goes below 500:  */
@media screen and (max-height: 600px) {
    div.chartcontainer #relatedindicators {
        display: none;
    }
    /* Make .chart expand to fill the gap: */
    div.chartcontainer .chart {
        height: 90% !important;
        margin-bottom: 1%;
    }
}

div.chartcontainer #relatedindicators div {
    flex-direction: row;
    margin: 0 auto;
    margin-top: -0em;
    padding-bottom: 10em;
    height: auto;
    /* border-radius: 5px; */
}

/* div.chartcontainer #relatedindicators a:hover {

} */

div.chartcontainer .favoriteElementLogo {
    margin: 1em 1em 0em 1em;
    height:50px !important;
    width:50px !important;
    color: white;

}

div.chartcontainer article {
    opacity: 0.9
}

div.chartcontainer article p {
    margin: 0;
    padding: 0;
    position:relative;
}

div.chartcontainer h3 {
    float:left;
}

/* Right-side infobox */
.right-info-wrapper {
    min-height: 100%;
    width: 22em;
    max-width:15%;
    border-left: 1px solid var(--nav-bg-color);   
    margin-left: -1px;
    background: var(--sides-bg-color);
    color: white;
    margin-top:0;
    margin-bottom: 1em;
    /* margin-right: 1em; */
    z-index: 1;
    overflow-x: visible;
    scrollbar-width: 0;
    transition: all 1s ease-in-out;
}

.right-info-wrapper::-webkit-scrollbar {
    display: none;
}

.right-info-wrapper:hover {
    transition: all 0.2s ease;
}
.toggleInfoBox {
    opacity: 0;
    cursor: pointer;
    transition: all 1s ease-in-out;
}

.right-info-wrapper:hover .toggleInfoBox {
    opacity: 1;
    transition: all 0.2s ease-in-out;
    top: 10px;
}

.toggleInfoBox:hover {
    color:lightgray !important;
}

.right-info-wrapper > div {
    /* border:5px solid black; */
    margin-left:0.0em;
    margin-top:10%;


    
}

.right-info-wrapper span {
    margin-top:1.5em;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: "";
    margin-bottom:0.5em;

}

.right-info-wrapper span section {
    margin-bottom:2em;
    border-radius: 5px;

}

.right-info-wrapper span section h3 {
    color:white;
    font-size: smaller;
    text-align:left;
    margin-left:9px;
}

#aside-information {
    border-bottom: 1px var(--nav-bg-color) !important;
}

.expandinfobox {
    transition: all 2.5s ease;
}

@keyframes expandinfoanimation{
    0% {transform:rotate(0deg);}
    25% {transform:rotate(15deg);}
    75% {transform:rotate(-15deg);}
    100% {transform:rotate(0deg);}
}

.expandinfobox:hover {
    animation: expandinfoanimation 2.5s ease;
    animation-iteration-count: infinite;
    font-size:1.0em;
    transition: all 2.5s ease;


}

