/*                                       custom scrollbar */
#menuHeader {
  margin: 10px 20px;
  text-align: center;
  color: #fff;
}
.sponserLogo {
  margin-top: 10px;
  width: 100%;
  height: auto;
}

#menubody {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
} /* custom scrollbar */
#menubody::-webkit-scrollbar {
  width: 0;
}
.menuRoot {
  padding: 5px;
   /* background-color: var(--menu-li-color);  */
  margin-bottom: 3px;
  z-index: 100;
}
.menuRoot:hover {
  background-color: var(--hover-color);
  color: var(--text-color-hover);
}
#searchBar {
  width: 140px;
  margin-left: -15px;
  padding: 5px;
}
#searchUl {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
}

#searchUl::-webkit-scrollbar-track {
  background-color: white;
}

#searchUl li a {
  border-bottom: 2px solid var(--bg-color);

  background-color: #e2e2b5;
  padding: 5px 0 3px 5px;
  text-decoration: none;

  color: black;
  display: block;
}
#searchUl li a:hover:not(.header) {
  background-color: var(--hover-color);
  color: var(--text-color);
}

.categoryName {
  color: var(--text-color);
  font-size: medium;
  margin-bottom: 10px;
  text-align: left;
}
.favoriteElement {
  padding: 3px 20px;
  margin: 0;
  display: inline-block;
  width: 30%;

}

.favoriteElementLogo {
  width: 20px;
  height: 20px;
  color: var(--text-color);
  cursor: pointer;
  stroke:white !important;
}

.favoriteElementLogo path {
  stroke: white;
}
.favoriteElementLogo:hover {
  color: rgb(255, 0, 0);
}
.favoriteElementLogoText {
  position: absolute;
  margin: 0;
  border: none;
  padding: 10px;
  color: var(--text-color);
  background: #af5002bc;
  opacity: 0;
  visibility: hidden;
  display: none;
  z-index: +1000;
  border-radius: 5px;
}

.favoriteElementLogo:hover + .favoriteElementLogoText {
  visibility: visible;
  display: block;
  opacity: 1;
}

/* menuRootLevel2 */
.menuRootLevel2 {
  position: absolute;
  top: 35px;
  bottom: 0;
  /* background-color: transparent; */
  background-color: var(--bg-color);
  z-index: 50;
  left: -400px;
  width: 300px;
  border: 1px solid whitesmoke;
}
.menuRootLevel2::-webkit-scrollbar{
  scrollbar-width: none;
  display: none;

}
.menuRoot:hover .menuRootLevel2 {
  animation-name: menuRootLevel2Move;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes menuRootLevel2Move {
  from {
    display: none;
    opacity: 0;
    left: 400px;
  }
  to {
    display: block;
    opacity: 1;
    /* left: 220px; */
    left: 12%;
  }
}

.categoryHeader {
  background-color: var(--text-color);
  color: #000000;
  width: 300px;
  font-size: medium;
  display: block;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
}

.categorybody {
  padding: 10px;
  background-color: var(--bg-color);
}

.categorybodyElement {
  color: var(--text-color)
}

.categorybodyElementHeader {
  border-bottom: 1px solid var(--text-color);
  margin-bottom: 5px;
}

.categorybodyIcone {
  float: left;
  color: var(--text-color);
  margin: 2px 4px 0 0;
  width: 15px;
  height: 15px;
}
.categorybodyName {
  color: var(--text-color);
  font-size: small;
}
.categorybodyElementMain {
  column-count: 2;
  overflow-wrap: break-word;
}

.categorybodyIndicator_name,
.categorybodyIndicator_name a {
  color: var(--text-color);
  font-size: 0.8rem;
  margin: 2px 0;
  float: left;
  width: 140px;
}

.categorybodyIndicator_name:hover,
a:hover {
  color: var(--hover-color);
}
.categoryscroll {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--bg-color);
  min-height: 400px;
}

.categorybodyIndicator_nameText {
  position: fixed;
  top: 76px;
  left: 450px;
  margin: 0;
  border: none;
  padding: 10px;
  color: var(--text-color);
  background: #375366cd;
  opacity: 0;
  visibility: hidden;
  display: none;
  z-index: +1000;
  border-radius: 5px;
  width: 300px;
  min-height: 400px;
  margin: auto 10px 10px auto;
}

.categorybodyIndicator_name:hover,
a:hover + .categorybodyIndicator_nameText {
  visibility: visible;
  display: block;
  opacity: 1;
}
