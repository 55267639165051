body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#heroImage {
  position: fixed;
  object-fit: fill;
  width: 100vw;
  height: 100%;
  z-index: -100;

}



/* Style the cards */

#USN_logo {


  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  object-fit: cover;
  width: 20rem;
  background-color: #4646a5c2;
  border-radius: calc(var(--curve) * 0.5px);

}

#USN_logoContener {
  width: 100%;

  
}


/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

#HomeFelles {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* CARDS -------------------------------------------------------------------------------------------------*/
:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 4rem 10%;
  padding: auto;
  list-style-type: none;

  padding-inline-start: 0;

  float: left;
}

.card {
  position: relative;
  display: block;
  height: 100%;

  max-width: 95%;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  margin: auto;
}

.card__image {
  width: 100%;
  height: 100%;
}

.card__image:hover {
  transform: scale(1.2);
  transition: 1s ease-in-out;
}

.card__image:not(:hover) {
  transform: scale(1);
  transition: .5s ease-in-out;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: .2s ease-in-out;
}



.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 10px;

  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
  background-color: #412717aa;

}

.card__thumb {
  flex-shrink: 0;
  width: 70%;
  height: 70%;
  margin-left: 10px;

  object-fit: fill;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  fill: #412717aa;
}





.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: .8em;
  color: #D7BDCA;
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.cadrText {
  position: absolute;
  z-index: 100;
  color: #fff;
  bottom: 0;
}


