
.highcharts-data-table {
  background-color: rgb(0, 255, 98);
  max-width: 60%;
  max-height: 70%;
  position: absolute;
  top: 185px;
  overflow: auto;
}

.highcharts-data-table table {
  border-collapse: collapse;
  background-color: rgb(214, 214, 214);
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  border: 1px solid rgb(0, 0, 0);
  padding: 0.5em;
}
.highcharts-data-table tr:nth-child(even),
.highcharts-data-table thead tr {
  background: var(--text-color);
}
.highcharts-data-table tr:hover {
  background: rgb(122, 179, 179);
}
.highcharts-data-table caption {
  border-bottom: none;
  font-size: 1.1em;
  font-weight: bold;
}


.allChat{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.highcharts-button-box+text {
  fill: var(--text-color) !important;
}
