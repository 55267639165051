@media only screen and (min-width: 700px) {
  #HomeDesktopContainer {
    display: flex;
    grid-template-areas:
      "menuContainer tabsContainer tabsContainer"
      "menuContainer graphContainers infoContainer";

    grid-template-rows: 35px min-content;
    grid-template-columns: 150px auto 200px;
    background-color: rgb(221, 221, 221);
  }
  @media only screen and (min-width: 1000px) {
    #HomeDesktopContainer {
      grid-template-columns: 150px auto 250px;
    }
  }
  #menuContainer {
    grid-area: menuContainer;
    background-color: var(--bg-color);
    color: var(--text-color);
    width:40rem;
    font-size: 1em !important
  }
  
  #menuContainer::-webkit-scrollbar {
    width: 0;
  }
  #tabsContainer {
    grid-area: tabsContainer;
    overflow: hidden;
    margin: 0 5px 0 5px;
    height: 40px;
  }
  #graphContainers {
    grid-area: graphContainers;
    background-color: var(--bg-color);
    margin: 0 0 5px 5px;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
  }
  #infoContainer {
    grid-area: infoContainer;
    background-color: var(--bg-color);
    margin: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    z-index: 10;
    width:30rem;
  }
}
