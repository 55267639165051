#openInfo,
#openMenu,
#close {
  position: absolute;
  height: 25px;
  width: 45px;
  z-index: 1;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: #4caf50; /* Green */
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}
#openMenu {
  left: 10px;
}
#close {
  background-color: #f44336;
}

#omveriable {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  top: 0;
  animation-duration: 0.4s;
}

#menu {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  top: 0;
  animation-duration: 0.4s;
}
.disactive {
  left: 1000px;
  animation-name: example;
}
@keyframes example {
  from {
    left: 0;
  }
  to {
    left: 1000px;
  }
}
.active {
  left: 0;
  animation-name: example2;
}
@keyframes example2 {
  from {
    left: 1000px;
  }
  to {
    left: 0;
  }
}

.menudisactive {
  right: 1000px;
  animation-name: example3;
}
@keyframes example3 {
  from {
    right: 0;
  }
  to {
    right: 1000px;
  }
}
.menuactive {
  right: 0;
  animation-name: example23;
}
@keyframes example23 {
  from {
    right: 1000px;
  }
  to {
    right: 0;
  }
}
