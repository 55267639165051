#infoContainer {
  display: grid;
  grid-template-areas:
    "omVariabelenContainer"
    "relatedIndicatorsContainer"
    "relaterteDokumenterContainer";
  grid-template-rows: 1fr 80px 120px;
  grid-template-columns: 1fr;
  background-color: var(--bg-color);
  font-size: small;
  overflow: hidden;
  margin-top:0.4em;
}

#omVariabelenContainer {
  grid-area: omVariabelenContainer;
  padding: 10px 10px 0 10px;
  background-color: #515151;
  overflow: hidden;
}
h3 {
  color: var(--text-color);
  margin: 0;
}
#omVariabelenText {
  color: #cadae2;
  font-size: small;
  overflow-y: auto;
}

#relatedIndicatorsContainer {
  grid-area: relatedIndicatorsContainer;
  padding: 10px 10px 0 10px;
  margin: 3px 0;
  background-color: #515151;
}

#relaterteDokumenterContainer {
  grid-area: relaterteDokumenterContainer;
  padding: 10px;
  background-color: #515151;
  overflow-y: auto;
}

#relatedIndicators,
#relatedIndicators ol {
  margin: 0;
  padding: 0;
  margin-block-start: auto;
  padding-inline-start: auto;
  float: left;
}

.relatedIndicatorsLogo {
  width: 30px;
  height: 30px;
  color: var(--text-color);
  cursor: pointer;
  margin-top: 5px;
}

.relatedIndicatorsLogo:hover {
  color: rgb(255, 0, 0);
}

.relatedIndicatorsLogoText {
  position: absolute;
  margin: 0;
  border: none;
  padding: 10px;
  color: var(--text-color);
  background: #000000;
  opacity: 0;
  visibility: hidden;
  display: none;
  z-index: +1000;
  border-radius: 5px;
}

.relatedIndicatorsLogo:hover + .relatedIndicatorsLogoText {
  visibility: visible;
  display: block;
  opacity: 1;
}

#relaterteDokumenterul {
  overflow-y: auto;
  list-style-type: none;
  margin: 0 -5px;
  padding: 0;
}

#relaterteDokumenterul li a {
  background: var(--bg-color);
  color: var(--text-color);
  margin: 5px;
  list-style: none;
  cursor: pointer;
  display: block;
  color: rgb(190, 190, 190);
  padding: 8px;
  text-decoration: none;
  border-radius: 5px;
}

#relaterteDokumenterul li a:hover {
  background-color: rgb(255, 0, 0);
  color: white;
}
